.language-box {
    color: white;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    position: relative;
    animation: float 3s ease-in-out infinite;
    transition: transform 0.3s ease-in-out;
}

@keyframes float {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
}

.language-box:hover {
  transform: translateY(-5px) scale(1.05);
}
.language-box2 {
    color: white;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    position: relative;
    animation: float 3s ease-in-out infinite;
    transition: transform 0.3s ease-in-out;
}

@keyframes float-animation {
  0% { transform: translateY(0) rotate(0deg); }
  50% { transform: translateY(20px) rotate(2deg); }
  100% { transform: translateY(0) rotate(0deg); }
}



@keyframes shake-and-scale {
  0% { transform: translateX(0) scale(1); }
  30% { transform: translateX(calc(40vw - 150px)) scale(1); } /* Move to edge */
  90% { transform: translateX(0) scale(1.4); }
  100% { transform: translateX(0) scale(1.4); } /* Scale up */
}

.shake-and-scale {
  animation: shake-and-scale 0s ease-in-out forwards;
  animation-fill-mode: forwards;
}

@keyframes push-away {
  0% { transform: translateX(0) scale(1); }
  20% { transform: translateX(10px) scale(1.05); }
  50% { transform: translateX(-500px) rotate(-10deg) scale(0.8); }
  100% { transform: translateX(-100vw) rotate(-30deg) scale(0.5); }
}

@keyframes center-move {
  0% { transform: translateX(0) scale(1.4); }
  100% { transform: translateX(0) scale(1.4); }
}

@keyframes hit-impact {
  0%, 50% { opacity: 1; transform: scale(1); }
  100% { opacity: 0; transform: scale(1.5); }
}

.push-away {
  animation: push-away 0s ease-in forwards;
}

.center-move {
  animation: center-move 0s ease-in-out forwards;
}

.language-box.hit-effect::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(255,0,0,0.5) 50%, rgba(255,255,255,0) 70%); 
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  animation: hit-impact 0.5s ease-in-out;
}

@keyframes final-scale-brightness {
    0% { transform: translateX(0) scale(1.4);filter: brightness(1); } /* Scale up */
  40% { transform: scale(1.4); filter: brightness(1.5); }
}

.final-scale-brightness {
  animation: final-scale-brightness 1s ease-in-out forwards;
}

@keyframes logo-scale {
  0% { transform: scale(1); filter: brightness(1);  }
  100% { transform: scale(1.6);; filter: brightness(1.2);  }
}

.logo-scale {
    animation: scaleAnimation 2s forwards;
  }
  
  @keyframes scaleAnimation {
    0% {
      transform: scale(0);
    }
    70% {
      transform: scale(1.3);
    }
    100% {
      transform: scale(1);
    }
  }
  
  .text-slide-in-left {
    animation: slideInLeft 2s forwards;
  }
  
  @keyframes slideInLeft {
    0% {
      transform: translateX(-100%);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  .text-slide-in-right {
    animation: slideInRight 2s forwards;
  }
  
  @keyframes slideInRight {
    0% {
      transform: translateX(100%);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
  .darken-bg {
  background-color: #000 !important;
}

@keyframes shrink {
    0% { transform: translateX(0) scale(1) } /* Scale up */
  40% { transform:  translateX(0) scale(0) }
}

.shrink{
    animation: shrink 0.5s ease-in-out forwards;

}