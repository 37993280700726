@keyframes scaleCart {
    0% {
      transform: scale(1);
    }
    30% {
      transform: scale(0.8);
    }
    60% {
      transform: scale(1.3);
    }
    100% {
      transform: scale(1);
    }
  }
  
  @keyframes changeBorderColor {
    0% {
      border-color: transparent;
      color: inherit;
    }
    50% {
      border-color: #3f51b5; /* Change this to your desired color */
      color: #3f51b5; /* Change this to your desired color */
    }
    100% {
      border-color: transparent;
      color: inherit;
    }
  }
  